<template>
  <div>
    <Navbar/>
    <div class="">
      <b-row class="">
        <!-- Brand logo-->
        
        <!-- /Brand logo-->
  
        <!-- Left Text-->
        <b-col
          lg="6"
          class="d-none d-lg-flex align-items-center"
          
        >
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5 prime-blue-background"
          >
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </b-col>
        <!-- /Left Text-->
  
        <!-- Register-->
        <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Forgot Password? 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Enter your email and we'll send you instructions to reset your
              password
            </b-card-text>
  
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
              >
                <b-form-group label="Email" label-for="forgot-password-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-modal ref="modal1" ok-only id="modal-1" title="Mail Sent" @ok="redirectToLogin">
                      <p v-if="errors.length == 0">
                        A mail has been sent to <b> {{ userEmail }} </b> with
                        instruction to reset the password!
                      </p>
                      <p v-else>Please write a valid email!</p>
                    </b-modal>
                  </validation-provider>
                </b-form-group>
  
                <b-button
                variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				  style="background: #4385f5; color: white;"
                  @click="resetPwd()"
                  
                  block
                >
                  Send reset link
                </b-button>
              </b-form>
            </validation-observer>
  
            <p class="text-center mt-2">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </p>
          </b-col>
        </b-col>
        <!-- /Register-->
      </b-row>
      <!-- Virtual address containing Popup! -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";

import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import Navbar from "../navbar/Navbar.vue";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {
    Navbar,
    VuexyLogo,
    BModal,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      validEmail: false,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.validEmail = this.$refs.simpleRules.validate();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    ...mapActions({ resetPass: "resetPass/resetPwd" }),
    validationForm() {},
    resetPwd() {
      console.log(this.userEmail);
	  if(this.userEmail.length > 0){
		this.$refs.modal1.show();
		this.resetPass({ email: this.userEmail.toLowerCase() });
	  }
    },
	redirectToLogin (){
		this.$router.push("/login");
	},
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
